import { ProjectSettings } from '@valiot/settings'
import packageJson from '../package.json'
import Logo from 'components/Logo/Logo'
const settings: ProjectSettings = {
  version: packageJson.version,
  title: 'Bydsa - Valiot',
  copilotEnabled: true,
  api: {
    http: import.meta.env.VITE_API_URL,
    wss: import.meta.env.VITE_API_WSS,
    valiotWorker: import.meta.env.VITE_WORKER,
    ssoUrl: import.meta.env.VITE_SSO_URL,
    routerCode: import.meta.env.VITE_ROUTER_CODE,
  },
  customerLogo: {
    src: <Logo />,
  },
  analytics: {
    enabled: true,
    siteId: import.meta.env.VITE_MATOMO_SITE_ID,
  },
  locale: {
    default: 'es',
    projectId: import.meta.env.VITE_LOCIZE_PROJECT_ID,
  },
  support: {
    enabled: true,
  },
  kioskMode: {
    enabled: false,
  },
  sidebarDefault: 'expanded',
  home: 'maintenance/schedule',
  routes: [],
  dropdowns: [],
  sections: [
    {
      path: 'mps',
      title: 'MPS',
      directory: 'mps',
      routes: [
        {
          path: 'sales',
          text: 'Requerimientos de Venta',
          icon: 'list-alt',
          component: 'SalesOrders',
          permissions: 'all',
        },
      ],
      dropdowns: [],
    },
    {
      path: 'maintenance',
      title: 'Mantenimiento',
      directory: 'maintenance',
      routes: [
        {
          path: 'schedule',
          text: 'Paros',
          icon: 'tools',
          component: 'Stops',
          permissions: 'all',
        },
        {
          path: 'stops-manage',
          text: 'Administrar motivos paros',
          component: 'ManageReasonsStops',
          permissions: 'all',
          hidden: true,
        },
      ],
      dropdowns: [],
    },
    {
      path: 'setup',
      title: 'Configuración',
      directory: 'setup',
      routes: [],
      dropdowns: [
        {
          path: 'admin',
          text: 'Administración',
          directory: 'admin',
          icon: 'user-shield',
          permissions: 'all',
          routes: [
            {
              path: 'users',
              text: 'Usuarios',
              iconSource: 'fas',
              icon: 'user',
              component: 'Users',
              permissions: 'all',
              // exactUrl: false,
              hidden: false,
            },
            {
              path: 'user-groups',
              text: 'Grupos',
              iconSource: 'fas',
              icon: 'users',
              component: 'Groups',
              permissions: 'all',
              // exactUrl: false,
              hidden: false,
            },
            {
              path: 'facility-allocation',
              text: 'Asignación a Plantas',
              iconSource: 'fas',
              icon: 'industry',
              component: 'TenancyAdmin',
              exactUrl: false,
              hidden: false,
            },
          ],
        },
      ],
    },
  ],
}

export default settings
